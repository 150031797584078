// TODO: remove plant id from details

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Button,
  MenuItem,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";

import Map from "../components/Map";
import ConfirmDeleteDialog from "../components/ConfirmDelete";
import CreatePlantDialog from "../components/CreatePlantDialog";
import EditIcon from "@mui/icons-material/Edit";
import apiService from "../services/apiService";
import CreateInverterDialog from "../components/CreateInverterDialog";
import InvertersGrid from "../components/InvertersGrid";

function PlantDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  // State for plant details
  const [plant, setPlant] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedPlant, setEditedPlant] = useState({});
  const [isCreatePlantDialogOpen, setIsCreatePlantDialogOpen] = useState(false);
  const [plantEditErrors, setPlantEditErrors] = useState({});

  // State for inverters
  const [inverters, setInverters] = useState([]);
  const [isInverterDialogOpen, setIsInverterDialogOpen] = useState(false);

  // State for deletion confirmation
  const [isDeleting, setIsDeleting] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  // Fetches the plant data
  const fetchPlant = async () => {
    try {
      const response = await apiService.getPlant(id);

      if (response && response.hasOwnProperty("plant")) {
        setPlant(response.plant);
        setEditedPlant(response.plant);
      } else {
        console.error("Invalid response format. 'plants' property not found.");
      }
    } catch (error) {
      console.error("Error fetching plants:", error);
    } finally {
      setIsLoading(false); // Set loading state to false once data is fetched
    }
  };

  // Fetch plants
  React.useEffect(() => {
    fetchPlant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch inverters
  React.useEffect(() => {
    const fetchInverters = async () => {
      try {
        const response = await apiService.getInverters(id);

        if (response && response.hasOwnProperty("inverters")) {
          setInverters(response.inverters);
        } else {
          console.error(
            "Invalid response format. 'inverters' property not found."
          );
        }
      } catch (error) {
        console.error("Error fetching inverters:", error);
      }
    };

    fetchInverters();
  }, [id]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setPlantEditErrors({});
    setIsEditing(false);
    setEditedPlant(plant);
  };

  const handlePlantInputChange = (e) => {
    /*
    Gets changed everytime the input to a TextField gets changed
    */
    const { name, value } = e.target;
    setEditedPlant((prevPlant) => ({
      ...prevPlant,
      [name]: value,
    }));
  };

  const handleSaveEdit = async () => {
    if (!checkPlantEditDialogErrors()) {
      console.log("Form has errors");
      return;
    }
    try {
      const response = await apiService.updatePlant(
        id,
        getChangedProperties(editedPlant)
      );

      // Handles the case when the locatio is not found
      // The backend does, however, not throw any error
      // for wrong locations at the moment
      if (response.message === "location not found") {
        changeEditErrors("location_street", "Location not found");
        changeEditErrors("location_zip", "Location not found");
        changeEditErrors("location_city", "Location not found");
        changeEditErrors("location_country", "Location not found");
        return;
      }
      if (response) {
        changeEditErrors({});
        setEditedPlant(response.plant); // Update the state with the latest data
        console.log("Plant details updated successfully");

        setIsEditing(false);
        fetchPlant();
      } else {
        console.error(
          "Invalid response format after update. 'plant' property not found."
        );
      }
    } catch (error) {
      console.error("Error saving plant details:", error);
    }
  };

  const checkPlantEditDialogErrors = () => {
    /*
    Checks if the input of the form values are valid.
    Sets the error below the fields if it is not the case.
    */
    const errors = {};

    if (!editedPlant.name) {
      errors.name = "Plant name is required";
    }
    if (!editedPlant.commissioning_date) {
      errors.commissioning_date = "Commissioning date is required";
    } else if (
      !editedPlant.commissioning_date.match(/^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/)
    ) {
      errors.commissioning_date = "Date not valid";
    } else if (
      (() => {
        const [day, month, year] = editedPlant.commissioning_date.split(".");
        const isoDateString = `${year}-${month}-${day}`;
        const date1 = new Date(isoDateString);

        const date2 = new Date();

        return date1 > date2;
      })()
    ) {
      errors.commissioning_date = "Date must not lie in the future";
    }
    if (!editedPlant.location_street) {
      errors.location_street = "Street is required";
    }
    if (!editedPlant.location_zip) {
      errors.location_zip = "ZIP code is required";
    }
    if (!editedPlant.location_city) {
      errors.location_city = "City is required";
    }
    if (!editedPlant.location_country) {
      errors.location_country = "Country is required";
    }
    if (!editedPlant.role) {
      errors.role = "Role is required";
    }
    setPlantEditErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getChangedProperties = (editedPlant) => {
    /*
    Returns a object with all the entries of the edited
    values that have been changed with respect to the plant
    */
    return Object.fromEntries(
      Object.entries(editedPlant).filter(([key, value]) => value !== plant[key])
    );
  };

  const deletePlant = async () => {
    /*
    Technically the plant does not get deleted but only set inactive
    */
    setIsDeleting(false);

    try {
      const { response, data } = await apiService.deletePlant(id);
      if (response && response.status < 400) {
        if (data && data.hasOwnProperty("message")) {
          console.log(data.message);
        } else {
          console.error(
            "Invalid response format. 'message' property not found."
          );
        }
      } else {
        console.error("Error something went wrong");
      }
    } catch (error) {
      console.error("Error deleting plant:", error);
    } finally {
      navigate("/plants");
    }
  };

  const handleSetActive = async () => {
    /*
    Sets an unactive plant back to active
    */
    try {
      const response = await apiService.updatePlant(id, { active: true });
      if (response && response.plant) {
        setEditedPlant(response.plant); // Update the state with the latest data
        console.log("Plant details updated successfully");
      } else {
        console.error(
          "Invalid response format after update. 'plant' property not found."
        );
      }
    } catch (error) {
      console.error("Error saving plant details:", error);
    } finally {
      setIsEditing(false);

      navigate("/plants");
    }
  };

  const changeEditErrors = (name, value) => {
    /*
    Changes a value of the errors for the editing form
    */
    setPlantEditErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  const duplicatePlant = async () => {
    /*
    Duplicates a plant with all its inverters and strings
    */

    const newPlant = { ...plant };
    newPlant.name = plant.name + " copy";
    // use apiService to create a new plant
    var response = await apiService.createPlant(newPlant);

    // wait for the response
    if (!response?.id) {
      // If the response is invalid, log the error
      console.error("Invalid response format. 'plant' property not found.");
      return;
    }

    // copy all inverter and strings
    for (let index in inverters) {
      let inverter = inverters[index];
      let newInverter = { ...inverter };
      const inverter_response = await apiService.createInverter(
        response.id,
        newInverter
      );

      const string_response = await apiService.getStrings(id, inverter.id);

      if (string_response && string_response.hasOwnProperty("strings")) {
        let strings = string_response.strings;

        for (let jndex in strings) {
          let string = strings[jndex];
          let newString = { ...string };
          await apiService.createString(
            response.id,
            inverter_response.id,
            newString
          );
        }
      }
    }

    navigate(`/plants/${response.id}`);
  };

  // Height of the detail and map card
  // When edititing and when normally
  const detailsHeights = ["700px", "450px"];

  return (
    <div>
      <ConfirmDeleteDialog
        isOpen={isDeleting}
        onClose={() => {
          setIsDeleting(false);
        }}
        onConfirm={deletePlant}
        title="Are you sure you want to set this plant inactive?"
        text="This action should be performed for plants that are no longer in use. The plant will still be visible in the inactive plants section."
      />

      <CreatePlantDialog
        isOpen={isCreatePlantDialogOpen}
        onClose={() => {
          setIsCreatePlantDialogOpen(false);
        }}
      />

      {!isLoading ? (
        <>
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "20px" }}
            //direction="row"
            //justifyContent="center"
            //alignItems="stretch"
          >
            {/* Grid has max two horizontal items, items are stretched to use horizontal space */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Plant details */}
                <Grid item xs={12} sm={6}>
                  <Card
                    elevation={5}
                    style={{
                      height: isEditing ? detailsHeights[0] : detailsHeights[1],
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Typography variant="h5" component="div">
                          Details {plant.name}
                        </Typography>
                        {!isEditing && plant.active ? (
                          <IconButton onClick={handleEditClick} color="primary">
                            <EditIcon />
                          </IconButton>
                        ) : isEditing && plant.active ? (
                          <>
                            <Button onClick={handleSaveEdit} color="primary">
                              Save
                            </Button>
                            <Button
                              onClick={handleCancelEdit}
                              color="secondary"
                            >
                              Cancel
                            </Button>
                          </>
                        ) : null}
                      </div>

                      {isEditing ? (
                        <form>
                          {/* Plant name */}
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="Plant Name"
                                name="name"
                                value={editedPlant.name}
                                onChange={handlePlantInputChange}
                                fullWidth
                                margin="normal"
                                error={Boolean(plantEditErrors.name)}
                                helperText={plantEditErrors.name}
                                onKeyDown={(e) =>
                                  e.key === "Enter" ? handleSaveEdit() : null
                                }
                                onBlur={() => {
                                  if (!editedPlant.name) {
                                    changeEditErrors(
                                      "name",
                                      "Plant name is required"
                                    );
                                  } else {
                                    changeEditErrors("name", "");
                                  }
                                }}
                              />
                            </Grid>

                            {/* Commissioning date */}
                            <Grid item xs={12}>
                              <TextField
                                label="Commissioning Date"
                                name="commissioning_date"
                                value={editedPlant.commissioning_date}
                                onChange={handlePlantInputChange}
                                fullWidth
                                margin="normal"
                                error={Boolean(
                                  plantEditErrors.commissioning_date
                                )}
                                helperText={plantEditErrors.commissioning_date}
                                onKeyDown={(e) =>
                                  e.key === "Enter" ? handleSaveEdit() : null
                                }
                                onBlur={() => {
                                  if (!editedPlant.commissioning_date) {
                                    changeEditErrors(
                                      "commissioning_date",
                                      "Commissioning date is required"
                                    );
                                  } else if (
                                    !editedPlant.commissioning_date.match(
                                      /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
                                    )
                                  ) {
                                    changeEditErrors(
                                      "commissioning_date",
                                      "Date not valid"
                                    );
                                  } else if (
                                    (() => {
                                      const [day, month, year] =
                                        editedPlant.commissioning_date.split(
                                          "."
                                        );
                                      const isoDateString = `${year}-${month}-${day}`;
                                      const date1 = new Date(isoDateString);

                                      const date2 = new Date();

                                      return date1 > date2;
                                    })()
                                  ) {
                                    changeEditErrors(
                                      "commissioning_date",
                                      "Date must not lie in the future"
                                    );
                                  } else {
                                    changeEditErrors("commissioning_date", "");
                                  }
                                }}
                              />
                            </Grid>

                            {/* Street of the location */}
                            <Grid item xs={12}>
                              <TextField
                                label="Street"
                                name="location_street"
                                value={editedPlant.location_street}
                                onChange={handlePlantInputChange}
                                fullWidth
                                margin="normal"
                                error={Boolean(plantEditErrors.location_street)}
                                helperText={plantEditErrors.location_street}
                                onKeyDown={(e) =>
                                  e.key === "Enter" ? handleSaveEdit() : null
                                }
                                onBlur={() => {
                                  if (!editedPlant.location_street) {
                                    changeEditErrors(
                                      "location_street",
                                      "Street is required"
                                    );
                                  } else {
                                    changeEditErrors("location_street", "");
                                  }
                                }}
                              />
                            </Grid>

                            {/* ZIP code of the location */}
                            <Grid item xs={4}>
                              <TextField
                                label="Zip"
                                name="location_zip"
                                value={editedPlant.location_zip}
                                onChange={handlePlantInputChange}
                                margin="normal"
                                error={Boolean(plantEditErrors.location_zip)}
                                helperText={plantEditErrors.location_zip}
                                onKeyDown={(e) =>
                                  e.key === "Enter" ? handleSaveEdit() : null
                                }
                                onBlur={() => {
                                  if (!editedPlant.location_zip) {
                                    changeEditErrors(
                                      "location_zip",
                                      "ZIP code is required"
                                    );
                                  } else {
                                    changeEditErrors("location_zip", "");
                                  }
                                }}
                              />
                            </Grid>

                            {/* City of the location */}
                            <Grid item xs={8}>
                              <TextField
                                label="City"
                                name="location_city"
                                value={editedPlant.location_city}
                                onChange={handlePlantInputChange}
                                fullWidth
                                margin="normal"
                                error={Boolean(plantEditErrors.location_city)}
                                helperText={plantEditErrors.location_city}
                                onKeyDown={(e) =>
                                  e.key === "Enter" ? handleSaveEdit() : null
                                }
                                onBlur={() => {
                                  if (!editedPlant.location_city) {
                                    changeEditErrors(
                                      "location_city",
                                      "City is required"
                                    );
                                  } else {
                                    changeEditErrors("location_city", "");
                                  }
                                }}
                              />
                            </Grid>

                            {/* Country of the location */}
                            <Grid item xs={12}>
                              <TextField
                                label="Country"
                                name="location_country"
                                value={editedPlant.location_country}
                                onChange={handlePlantInputChange}
                                fullWidth
                                margin="normal"
                                error={Boolean(
                                  plantEditErrors.location_country
                                )}
                                helperText={plantEditErrors.location_country}
                                onKeyDown={(e) =>
                                  e.key === "Enter" ? handleSaveEdit() : null
                                }
                                onBlur={() => {
                                  if (!editedPlant.location_country) {
                                    changeEditErrors(
                                      "location_country",
                                      "Country is required"
                                    );
                                  } else {
                                    changeEditErrors("location_country", "");
                                  }
                                }}
                              />
                            </Grid>

                            {/* Role */}
                            <Grid item xs={12}>
                              <Box sx={{ height: 10 }} />
                              <TextField
                                select
                                label="Role"
                                name="role"
                                value={editedPlant.role}
                                onChange={handlePlantInputChange}
                                fullWidth
                                margin="normal"
                                displayEmpty // Add this prop to display the empty value
                              >
                                <MenuItem value={"Private Owner"}>
                                  Private Owner
                                </MenuItem>
                                <MenuItem value={"Professional Owner"}>
                                  Professional Owner
                                </MenuItem>
                                <MenuItem value={"Professional Installer"}>
                                  Professional Installer
                                </MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                              </TextField>
                            </Grid>
                          </Grid>
                        </form>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          <p>
                            <strong>Plant Name: </strong>
                            {plant.name}
                          </p>
                          <p>
                            <strong>Commissioning Date: </strong>
                            {plant.commissioning_date}
                          </p>
                          {plant.active === 0 && (
                            <p>
                              <strong>Decommissioning Date: </strong>
                              {plant.decommissioning_date}
                            </p>
                          )}
                          <p>
                            <strong>Street: </strong>
                            {plant.location_street}
                          </p>
                          <p>
                            <strong>City: </strong>
                            {plant.location_zip} {plant.location_city}
                          </p>
                          <p>
                            <strong>Country: </strong>
                            {plant.location_country}
                          </p>
                          <p>
                            <strong>Location Latitude: </strong>
                            {plant.location_lat}
                          </p>
                          <p>
                            <strong>Location Longitude: </strong>
                            {plant.location_lon}
                          </p>
                          <p>
                            <strong>AC Power: </strong>
                            {plant.ac_power} kW
                          </p>
                          <p>
                            <strong>DC Power: </strong>
                            {plant.dc_power} kW
                          </p>
                          <p>
                            <strong>Role: </strong>
                            {plant.role}
                          </p>
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>

                {/* Map */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    height: detailsHeights[1],
                  }}
                >
                  <Card
                    elevation={5}
                    style={{
                      height: detailsHeights[1],
                    }}
                  >
                    <CardContent
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height: detailsHeights[1],
                      }}
                    >
                      {plant.location_lat && plant.location_lon ? (
                        <Map
                          center={[plant.location_lat, plant.location_lon]}
                          height="400px"
                          width="100%"
                          marker={{
                            id: plant.id,
                            name: plant.name,
                            latitude: plant.location_lat,
                            longitude: plant.location_lon,
                          }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: detailsHeights[1],
                          }}
                        >
                          <Typography variant="h5" component="div">
                            Map did not find the location
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            {/* Plant Management Card */}
            <Grid item xs={12}>
              <Card elevation={5}>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      display: "flex",
                    }}
                  >
                    {/* Button to move back to the plant overview */}
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={() => {
                          navigate("/plants");
                        }}
                      >
                        Back to overview
                      </Button>
                    </Grid>

                    {/* Button to add another plant */}
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={() => {
                          setIsCreatePlantDialogOpen(true);
                        }}
                      >
                        Add another plant
                      </Button>
                    </Grid>

                    {/* Button to duplicate the plant */}
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={duplicatePlant}
                      >
                        Duplicate Plant
                      </Button>
                    </Grid>

                    {/* Button to set the plant inactive */}
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {plant.active ? (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setIsDeleting(true);
                          }}
                        >
                          Set Plant inactive
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="info"
                          onClick={handleSetActive}
                        >
                          Set Plant active
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Inverter Grid Card */}
            <Grid item xs={12}>
              {plant.active ? (
                <Card elevation={5}>
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5" component="div">
                        Inverters
                      </Typography>
                    </div>

                    <InvertersGrid
                      inverters={inverters}
                      setIsInverterDialogOpen={setIsInverterDialogOpen}
                    />
                  </CardContent>
                </Card>
              ) : null}
            </Grid>
          </Grid>

          <CreateInverterDialog
            isOpen={isInverterDialogOpen}
            onClose={() => {
              setIsInverterDialogOpen(false);
            }}
            onCancel={() => {
              setIsInverterDialogOpen(false);
            }}
            plantId={id}
            plant={plant}
          />
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default PlantDetails;
