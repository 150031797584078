import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Card,
  Typography,
} from "@mui/material";
import apiService from "../../services/apiService";
import CreateInverterDialog from "../CreateInverterDialog";

const VerifyStatus = ({
  open,
  onClose,
  updatedInverter,
  setUpdatedInverter,
}) => {
  const [updateStatusDialogErrors, setUpdateStatusDialogErrors] = useState({});
  const [isCreateInverterDialogOpen, setIsCreateInverterDialogOpen] =
    useState(false);
  const [plant, setPlant] = useState({});
  const [askForNewInverter, setAskForNewInverter] = useState(false);

  const fetchPlant = async () => {
    try {
      const response = await apiService.getPlant(updatedInverter.pv_plant_id);

      if (response && response.hasOwnProperty("plant")) {
        setPlant(response.plant);
        console.log(plant);
      } else {
        console.error("Invalid response format. 'plants' property not found.");
      }
    } catch (error) {
      console.error("Error fetching plants:", error);
    }
  };
  useEffect(() => {
    fetchPlant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedInverter]);

  const changeDialogErrors = (name, value) => {
    /*
    Changes the errors in the dialog for the updating of a specific inverter
    */
    setUpdateStatusDialogErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  const handleUpdateStatusChange = (e) => {
    const { name, value } = e.target;
    setUpdatedInverter((currInverter) => ({
      ...currInverter,
      [name]: value,
    }));
  };

  const handleUpdateStatusConfirm = async () => {
    const errors = checkUpdateStatusChanges();
    if (Object.keys(errors).length !== 0) {
      console.log("Form has errors");
      console.log(updateStatusDialogErrors);
      return;
    }

    if (updatedInverter.newStatus === "replaced") {
      setIsCreateInverterDialogOpen(true);
    } else if (updatedInverter.newStatus === "broken") {
      // Create new Inverter with same strings
      setAskForNewInverter(true);
    } else {
      handleUpdate();
    }
  };

  const handleReplacedCallback = async () => {
    // Callback for replacement if the inverter was created
    setIsCreateInverterDialogOpen(false);
    handleUpdate();
  };

  const handleReplacedCallbackClose = async () => {
    // Callback for replacement if the inverter was not created
    setIsCreateInverterDialogOpen(false);
  };

  const handleUpdate = async () => {
    try {
      await apiService.updateInverterStatuses({
        changes: [updatedInverter],
      });
    } catch (error) {
    } finally {
      onClose();
    }
  };

  const checkDate = (dateEarliest, dateToCheck) => {
    const [day, month, year] = dateEarliest.split(".");
    const isoDateString = `${year}-${month}-${day}`;
    const date1 = new Date(isoDateString);

    const [day2, month2, year2] = dateToCheck.split(".");
    const isoDateString2 = `${year2}-${month2}-${day2}`;
    const date2 = new Date(isoDateString2);

    return date2.getTime() <= date1.getTime();
  };

  const checkDateNotInFuture = (date) => {
    const [day, month, year] = date.split(".");
    const isoDateString = `${year}-${month}-${day}`;
    const date1 = new Date(isoDateString);

    const today = new Date();

    return today.getTime() <= date1.getTime();
  };

  const checkUpdateStatusChanges = () => {
    /*
    Checks the validity of the input given to update the status
    */
    const errors = {};

    if (!updatedInverter.newStatus) {
      errors.newStatus = "New status is required";
    } else if (updatedInverter.newStatus === "operational") {
      return true;
    }
    if (
      updatedInverter.newStatus !== "operational" &&
      !updatedInverter.failureDate
    ) {
      errors.date = "Date is required";
    } else if (
      !updatedInverter.failureDate.match(/^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/)
    ) {
      errors.date = "Date is not valid";
    } else if (
      checkDate(updatedInverter.commissioning_date, updatedInverter.failureDate)
    ) {
      errors.date = "Date cannot be earlier than inverter commissioning date";
    } else if (
      checkDate(updatedInverter.earliest, updatedInverter.failureDate)
    ) {
      errors.date = "Date cannot be earlier than plant commissioning date";
    } else if (checkDateNotInFuture(updatedInverter.failureDate)) {
      errors.date = "Date cannot be in the future";
    }
    if (
      updatedInverter.newStatus === "broken" &&
      !updatedInverter.description
    ) {
      errors.description = "Description is required";
    }
    setUpdateStatusDialogErrors(errors);
    return errors;
  };

  return (
    <>
      <CreateInverterDialog
        isOpen={isCreateInverterDialogOpen}
        onClose={handleReplacedCallback}
        onCancel={handleReplacedCallbackClose}
        plant={plant}
        plantId={plant.id}
        navigateToInverter={false}
        defaultValues={{ ...updatedInverter, copyStrings: updatedInverter.id }}
      />

      <Dialog open={askForNewInverter}>
        <Card sx={{ p: 2 }}>
          <Typography variant="h6">
            Do you wish do create a new inverter with the strings from this
            broken one?
          </Typography>
          <Typography variant="body1">
            If the broken inverter has already been replaced, you can now create
            a new inverter with the same strings. This way, you don't need to
            recreate all the strings.
          </Typography>
          {/* Confirm and Cancel buttons positioned with spacing in between */}
          <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <Button
                color="secondary"
                onClick={() => {
                  setAskForNewInverter(false);
                  handleUpdate();
                }}
                sx={{ mr: 1 }}
              >
                Not now
              </Button>
              <Button
                onClick={() => {
                  setIsCreateInverterDialogOpen(true);
                  setAskForNewInverter(false);
                }}
              >
                Create new Inverter
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Dialog>

      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Update Inverter Status</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ height: 10 }} />

              {/* New status for the inverter */}
              <TextField
                select
                label="Status"
                name="newStatus"
                value={updatedInverter.newStatus}
                onChange={handleUpdateStatusChange}
                fullWidth
                margin="normal"
                displayEmpty
                error={Boolean(updateStatusDialogErrors.newStatus)}
                helperText={updateStatusDialogErrors.newStatus}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleUpdateStatusConfirm() : null
                }
                onBlur={() => {
                  if (!updatedInverter.newStatus) {
                    changeDialogErrors("newStatus", "New status is required");
                  } else {
                    changeDialogErrors("newStatus", "");
                  }
                }}
              >
                {updatedInverter.oldStatus === "operational" ? (
                  <MenuItem value="operational">Operational</MenuItem>
                ) : null}
                {updatedInverter.oldStatus === "operational" ||
                updatedInverter.oldStatus === "replaced" ? (
                  <MenuItem value="replaced">Replaced without failure</MenuItem>
                ) : null}
                {updatedInverter.oldStatus === "operational" ? (
                  <MenuItem value="broken">Broken</MenuItem>
                ) : null}
                {updatedInverter.oldStatus === "broken" ? (
                  <MenuItem value="repaired">Repaired</MenuItem>
                ) : null}
              </TextField>
            </Grid>

            {(updatedInverter.oldStatus !== "broken" &&
              updatedInverter.newStatus === "broken") ||
            updatedInverter.newStatus === "repaired" ||
            updatedInverter.newStatus === "replaced" ? (
              /* Date field that has different name depending on the new status */
              <Grid item xs={12}>
                <TextField
                  label={
                    updatedInverter.newStatus === "broken"
                      ? "Failure Date (DD.MM.YYYY)"
                      : updatedInverter.newStatus === "replaced"
                      ? "Replacement Date (DD.MM.YYYY)"
                      : updatedInverter.newStatus === "repaired"
                      ? "Repairment Date (DD.MM.YYYY)"
                      : "Date"
                  }
                  name="failureDate"
                  value={updatedInverter.failureDate}
                  onChange={handleUpdateStatusChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(updateStatusDialogErrors.date)}
                  helperText={updateStatusDialogErrors.date}
                  displayEmpty
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleUpdateStatusConfirm() : null
                  }
                  onBlur={() => {
                    if (
                      updatedInverter.newStatus !== "operational" &&
                      !updatedInverter.failureDate
                    ) {
                      changeDialogErrors("date", "Date is required");
                    } else if (
                      !updatedInverter.failureDate.match(
                        /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
                      )
                    ) {
                      changeDialogErrors("date", "Date is not valid");
                    } else if (
                      checkDate(
                        updatedInverter.commissioning_date,
                        updatedInverter.failureDate
                      )
                    ) {
                      changeDialogErrors(
                        "date",
                        "Date cannot be earlier than inverter commissioning date"
                      );
                    } else if (
                      checkDate(
                        updatedInverter.earliest,
                        updatedInverter.failureDate
                      )
                    ) {
                      changeDialogErrors(
                        "date",
                        "Date cannot be earlier than plant commissioning date"
                      );
                    } else if (
                      checkDateNotInFuture(updatedInverter.failureDate)
                    ) {
                      changeDialogErrors(
                        "date",
                        "Date cannot be in the future"
                      );
                    } else {
                      changeDialogErrors("date", "");
                    }
                  }}
                />
              </Grid>
            ) : null}

            {updatedInverter.newStatus === "broken" &&
            updatedInverter.oldStatus !== "broken" ? (
              /* Reason for of the broken inverter */
              <Grid item xs={12}>
                <TextField
                  select
                  label="Description"
                  name="description"
                  value={updatedInverter.description}
                  onChange={handleUpdateStatusChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(updateStatusDialogErrors.description)}
                  helperText={updateStatusDialogErrors.description}
                  displayEmpty
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleUpdateStatusConfirm() : null
                  }
                  onBlur={() => {
                    if (!updatedInverter.description) {
                      changeDialogErrors(
                        "description",
                        "Description is required"
                      );
                    } else {
                      changeDialogErrors("description", "");
                    }
                  }}
                >
                  <MenuItem value="Inverter hardware failure">
                    Inverter hardware failure
                  </MenuItem>
                  <MenuItem value={"Software Problem"}>
                    Software problem
                  </MenuItem>
                  <MenuItem value={"Fire started by PV Plant"}>
                    Fire started by PV Plant
                  </MenuItem>
                  <MenuItem value={"Fire started by Other"}>
                    Fire started by Other
                  </MenuItem>
                  <MenuItem value={"Earthquake"}>Earthquake</MenuItem>
                  <MenuItem value={"Flooding"}>Flooding</MenuItem>
                  <MenuItem value={"Hail"}>Hail</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </TextField>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateStatusConfirm} color="primary">
            Update Status
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerifyStatus;
