import React, { useState } from "react";
import {
  Button,
  Typography,
  Card,
  Grid,
  Box,
  Collapse,
  CardActionArea,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";

import { Link } from "react-router-dom";

const InvertersGrid = ({ inverters, setIsInverterDialogOpen }) => {
  /*
  Grid object for the inverters. It create a grid of cards where
  each inverter gets a card. Each card is a link to the details page
  of the corresponding inverter. Additionally, there is a card with a
  plus sign on it that serves to open the create inverter dialog.
  */
  const [areBrokenInverterVisible, setAreBrokenInverterVisible] =
    useState(false);
  const [areOperationalInverterVisible, setAreOperationalInverterVisible] =
    useState(true);

  // Separate operational and broken inverters
  const operationalInverters = inverters.filter(
    (inverter) => inverter.status === "operational"
  );
  const brokenInverters = inverters.filter(
    (inverter) => inverter.status === "broken" || inverter.status === "replaced"
  );

  const cardHeight = 150;

  return (
    <>
      {/* Operational Inverters */}
      <>
        <Box sx={{ height: 20 }} />
        <Button
          onClick={() =>
            setAreOperationalInverterVisible(!areOperationalInverterVisible)
          }
        >
          {areOperationalInverterVisible ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
          Operational Inverters
          {" ("}
          {operationalInverters.length || 0}
          {")"}
        </Button>
        <Collapse
          in={areOperationalInverterVisible}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ height: 20 }} />
          <Grid container spacing={2}>
            {/* Each Inverter gets a card with some information on it*/}
            {operationalInverters.map((inverter, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card elevation={3} style={{ height: cardHeight }}>
                  <CardContent>
                    <Link
                      to={`/plants/${inverter.pv_plant_id}/inverter/${inverter.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {/* Each card is a link to the detail page of the inverter*/}

                      <Typography
                        noWrap
                        variant="h6"
                        component="div"
                        style={{ marginBottom: 8 }}
                      >
                        {inverter.name}{" "}
                        {/* Replace with your inverter name property */}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <Grid
                          container
                          alignItems="center"
                          style={{ marginBottom: 8 }}
                        >
                          <Grid item xs={12}>
                            <strong>Nominal AC Power: </strong>
                            {inverter.ac_power} kW
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Connected DC Power: </strong>
                            {inverter.dc_power_manual} kW
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Commissioning Date: </strong>
                            {inverter.commissioning_date}{" "}
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Last Verification: </strong>
                            {inverter.last_status_update}{" "}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {/* Additional Card with a plus sign on it that opens the create inverter dialog*/}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                elevation={3}
                style={{
                  height: cardHeight,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardActionArea
                  onClick={() => {
                    setIsInverterDialogOpen(true);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <AddIcon fontSize="large" />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Collapse>
      </>

      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <hr />
      </div>

      {/* Broken Inverters */}
      <>
        <Button
          onClick={() => setAreBrokenInverterVisible(!areBrokenInverterVisible)}
        >
          {areBrokenInverterVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          Broken & Replaced Inverters
          {" ("}
          {brokenInverters.length || 0}
          {")"}
        </Button>
        <Box sx={{ height: 20 }} />
        <Collapse in={areBrokenInverterVisible} timeout="auto" unmountOnExit>
          <Grid container spacing={2}>
            {brokenInverters.map((inverter, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card elevation={3} style={{ height: "100%" }}>
                  <CardContent>
                    <Link
                      to={`/plants/${inverter.pv_plant_id}/inverter/${inverter.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography
                        noWrap
                        variant="h6"
                        component="div"
                        style={{ marginBottom: 8 }}
                      >
                        {inverter.name}{" "}
                        {/* Replace with your inverter name property */}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <Grid
                          container
                          alignItems="center"
                          style={{ marginBottom: 8 }}
                        >
                          <Grid item xs={12}>
                            <strong>Nominal AC Power: </strong>
                            {inverter.ac_power} kW
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Connected DC Power: </strong>
                            {inverter.dc_power_manual} kW
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Commissioning Date: </strong>
                            {inverter.commissioning_date}{" "}
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Last Verification: </strong>
                            {inverter.last_status_update}{" "}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </>
    </>
  );
};

export default InvertersGrid;
