import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Box,
  InputAdornment,
  Grid,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import apiService from "../services/apiService";
import ConfirmDeleteDialog from "../components/ConfirmDelete";
import CreateInverterDialog from "../components/CreateInverterDialog";
import CreateStringDialog from "../components/CreateStringDialog";
import StringsGrid from "../components/StringsGrid";
import History from "../components/History";

function InverterDetails() {
  // Gets the id of the plant and inverter from the url
  const { id, inverterId } = useParams();
  const navigate = useNavigate();

  const [plant, setPlant] = useState({});

  // State for inverter details
  const [inverter, setInverter] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [inverterEditValues, setInverterEditValues] = useState({});
  const [isSecondHandCheckboxOn, setIsSecondHandCheckboxOn] = useState(false);
  const [inverterEditErrors, setInverterEditErrors] = useState({});

  // String stuff
  const [strings, setStrings] = useState([{}]);
  const [isCreateStringDialogOpen, setIsCreateStringDialogOpen] =
    useState(false);
  const [isCreateInverterDialogOpen, setIsCreateInverterDialogOpen] =
    useState(false);

  // State for deletion
  const [isDeleting, setIsDeleting] = useState(false);

  // inverter manufacturere dopdown
  const [inverterDropdown, setInverterDropdown] = useState({});

  // Fetch inverter types for the dropdown
  useEffect(() => {
    const fetchInvertTypes = async () => {
      try {
        const response = await apiService.getInverterTypes();

        if (response && response.hasOwnProperty("types")) {
          setInverterDropdown(response.types);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching InverterTypes:", error);
      }
    };

    fetchInvertTypes();
  }, []);

  // Fetch plant details
  useEffect(() => {
    const fetchPlant = async () => {
      try {
        const response = await apiService.getPlant(id);

        if (response && response.hasOwnProperty("plant")) {
          setPlant(response.plant);
        } else {
          console.error(
            "Invalid response format. 'plants' property not found."
          );
        }
      } catch (error) {
        console.error("Error fetching plants:", error);
      }
    };

    fetchPlant();
  }, [id]);

  // Fetch inverter details
  const fetchInverterDetails = async () => {
    try {
      const response = await apiService.getInverter(id, inverterId);

      if (response && response.hasOwnProperty("inverter")) {
        setInverter(response.inverter);
        // Set form values to the inverter details
        setInverterEditValues({
          name: response.inverter.name || "",
          manufacturer: response.inverter.manufacturer || "",
          type: response.inverter.type || "",
          category: response.inverter.category || "",
          mounting: response.inverter.mounting || "",
          ac_power: response.inverter.ac_power || "",
          commissioning_date: response.inverter.commissioning_date || "",
          is_second_hand: response.inverter.is_second_hand || false,
        });
        setIsSecondHandCheckboxOn(response.inverter.is_second_hand);
      } else {
        console.error(
          "Invalid response format. 'inverter' property not found."
        );
      }
    } catch (error) {
      console.error("Error fetching inverter details:", error);
    }
  };
  useEffect(() => {
    fetchInverterDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch strings
  useEffect(() => {
    const fetchStrings = async () => {
      try {
        const response = await apiService.getStrings(id, inverterId);

        if (response && response.hasOwnProperty("strings")) {
          setStrings(response.strings);
        }
      } catch (error) {
        console.error("Error fetching strings:", error);
      }
    };

    fetchStrings();
  }, [id, inverterId]);

  const changeEditErrors = (name, value) => {
    /* 
    Changes one field of the edit errors 
    */
    setInverterEditErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  const handleInverterDetailsEditClick = () => {
    /*
    Handles the opening of the inverter detail edit page
    */
    setInverterEditValues({
      name: inverter.name || "",
      manufacturer: inverter.manufacturer,
      manufacturer_custom: "",
      type: inverter.type,
      type_custom: "",
      category: inverter.category || "",
      mounting: inverter.mounting || "",
      ac_power: inverter.ac_power || "",
      dc_power_manual: inverter.dc_power_manual || "",
      commissioning_date: inverter.commissioning_date || "",
      is_second_hand: inverter.is_second_hand || false,
      ac_cable_length: inverter.ac_cable_length || "",
      ac_cable_cross_section: inverter.ac_cable_cross_section || "",
      galvanic_separation: inverter.galvanic_separation || "",
      serial_number: inverter.serial_number || "",
    });
    setIsSecondHandCheckboxOn(inverter.is_second_hand);
    setIsEditing(true);
  };

  const handleInverterDetailsSaveEdit = async () => {
    /*
    Saves the changes made to the inverter when editing
    its details.
    */

    const formIsReady = checkInverterEditDialogErrors();
    if (!formIsReady) {
      console.log("There are errors in the form");
      console.log(inverterEditErrors);
      return;
    }

    try {
      // get the formdata that has been changed
      const changedInverterEditValues = Object.keys(inverterEditValues).reduce(
        (acc, key) => {
          if (
            inverterEditValues[key] &&
            inverterEditValues[key] !== inverter[key]
          ) {
            acc[key] = inverterEditValues[key];
          }
          return acc;
        },
        {}
      );

      // If there are no changes, do nothing
      if (Object.keys(changedInverterEditValues).length === 0) {
        setIsEditing(false);
        return;
      }

      // Submit your form data to the server
      console.log(changedInverterEditValues);
      await apiService.updateInverter(
        id,
        inverterId,
        changedInverterEditValues
      );
      setIsEditing(false);
      fetchInverterDetails();
    } catch (error) {
      console.error("Error updating inverter:", error);
    }
  };

  const checkDate = (dateEarliest, dateToCheck) => {
    const [day, month, year] = dateEarliest.split(".");
    const isoDateString = `${year}-${month}-${day}`;
    const date1 = new Date(isoDateString);

    const [day2, month2, year2] = dateToCheck.split(".");
    const isoDateString2 = `${year2}-${month2}-${day2}`;
    const date2 = new Date(isoDateString2);

    return date2.getTime() <= date1.getTime();
  };

  const checkDateNotInFuture = (date) => {
    const [day, month, year] = date.split(".");
    const isoDateString = `${year}-${month}-${day}`;
    const date1 = new Date(isoDateString);

    const today = new Date();

    return today.getTime() <= date1.getTime();
  };

  const checkInverterEditDialogErrors = () => {
    /*
    Checks if the input of the form values are valid.
    Sets the error below the fields if it is not the case.
    */

    if (inverterEditValues.manufacturer_custom) {
      inverterEditValues.manufacturer = inverterEditValues.manufacturer_custom;
    }
    if (inverterEditValues.type_custom) {
      inverterEditValues.type = inverterEditValues.type_custom;
    }

    const errors = {};

    if (!inverterEditValues.name) {
      errors.name = "Inverter name is required";
    }
    if (!inverterEditValues.manufacturer) {
      errors.manufacturer = "Manufacturer is required";
    }
    if (!inverterEditValues.type) {
      errors.type = "Type is required";
    }
    if (!inverterEditValues.category) {
      errors.category = "Category is required";
    }
    if (!inverterEditValues.mounting) {
      errors.mounting = "Place of installation is required";
    }
    if (!inverterEditValues.ac_power) {
      errors.ac_power = "AC power is required";
    } else if (inverterEditValues.ac_power < 0) {
      errors.ac_power = "AC power cannot be negative";
    }
    if (!inverterEditValues.dc_power_manual) {
      errors.dc_power_manual = "DC power is required";
    } else if (inverterEditValues.dc_power_manual < 0) {
      errors.dc_power_manual = "DC power cannot be negative";
    }
    if (!inverterEditValues.commissioning_date) {
      errors.commissioning_date = "Commissioning date is required";
    } else if (
      !inverterEditValues.commissioning_date.match(
        /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
      )
    ) {
      errors.commissioning_date = "Date not valid";
    } else if (
      checkDate(plant.commissioning_date, inverterEditValues.commissioning_date)
    ) {
      errors.commissioning_date =
        "Date cannot be earlier than plant commissioning date";
    } else if (checkDateNotInFuture(inverterEditValues.commissioning_date)) {
      errors.commissioning_date = "Date cannot lie in the future";
    }
    if (inverterEditValues.ac_cable_length < 0) {
      errors.ac_cable_length = "AC cable length cannot be negative";
    }
    if (inverterEditValues.ac_cable_cross_section < 0) {
      errors.ac_cable_cross_section =
        "AC cable cross sectioncannot be negative";
    }
    setInverterEditErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCancelEdit = () => {
    /* 
    Handles the closing of the inverter edit tab
    */
    setInverterEditErrors({});
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    /*
    This function gets called whenever a input of
    the edit tab is changed 
    */
    const { name, value } = e.target;

    // Changes the manufacturer and type from text to dropdown
    // if the value of the text was manually changed to empty
    if (name === "manufacturer_custom" && !value) {
      inverterEditValues.manufacturer = "";
      inverterEditValues.manufacturer_custom = "";
      inverterEditValues.type = "";
      inverterEditValues.type_custom = "";
    }
    if (name === "type_custom" && !value) {
      inverterEditValues.type = "";
      inverterEditValues.type_custom = "";
    }

    if (name === "is_second_hand") {
      inverterEditValues.is_second_hand = !inverterEditValues.is_second_hand;
      setIsSecondHandCheckboxOn(inverterEditValues.is_second_hand);
    } else {
      setInverterEditValues({
        ...inverterEditValues,
        [name]: value,
      });
    }
  };

  const deleteInverter = async () => {
    /*
    Deletes the inverter
    */
    try {
      await apiService.deleteInverter(id, inverterId);
      // Redirect to the project plantdetails page
      navigate(`/plants/${id}/`);
    } catch (error) {
      console.error("Error deleting inverter:", error);
    }
  };

  const duplicateInverter = async (
    navigateToNewInvert = false,
    keep_name = false
  ) => {
    /*
    Duplicates the inverter and all its strings
    */
    const inverterEditValues = { ...inverter };

    if (!keep_name) {
      inverterEditValues.name = inverter.name + " copy";
    }

    try {
      // Send the new inverter details to the server
      const response = await apiService.createInverter(id, inverterEditValues);

      if (
        response &&
        response.hasOwnProperty("message") &&
        response.hasOwnProperty("id")
      ) {
        // Optionally, you can update the inverters state with the new inverter
        // setInverters((prevInverters) => [...prevInverters, response.inverter]);
        console.log(response.message);
      } else {
        console.error("Invalid response format. 'message' property not found.");
        return;
      }

      // Duplicate all strings
      for (let index in strings) {
        let string = strings[index];
        let newString = { ...string };
        await apiService.createString(id, response.id, newString);
      }

      if (navigateToNewInvert) {
        navigate(`/plants/${id}/inverter/${response.id}`);
      }
    } catch (error) {
      console.error("Error creating inverter:", error);
    }
  };

  return (
    <div>
      <ConfirmDeleteDialog
        isOpen={isDeleting}
        onClose={() => {
          setIsDeleting(false);
        }}
        onConfirm={deleteInverter}
        text="The inverter will be deleted completely! Are you sure you want to delete it?"
      />

      <CreateInverterDialog
        isOpen={isCreateInverterDialogOpen}
        onClose={() => {
          setIsCreateInverterDialogOpen(false);
        }}
        onCancel={() => {
          setIsCreateInverterDialogOpen(false);
        }}
        plantId={id}
        plant={plant}
      />

      {/* Existing InverterDetails Card */}
      <Card style={{ maxWidth: 1200 }} elevation={5}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="div">
              Details {inverter.name}
            </Typography>
            {isEditing ? (
              <>
                <Button onClick={handleInverterDetailsSaveEdit} color="primary">
                  Save
                </Button>
                <Button onClick={handleCancelEdit} color="secondary">
                  Cancel
                </Button>
              </>
            ) : inverter.status === "operational" ? (
              <IconButton
                onClick={handleInverterDetailsEditClick}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            ) : null}
          </div>
          {isEditing ? (
            <form>
              <TextField
                label="Inverter Name"
                name="name"
                value={inverterEditValues.name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.name)}
                helperText={inverterEditErrors.name}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                onBlur={() => {
                  if (!inverterEditValues.name) {
                    changeEditErrors("name", "Name is required");
                  } else {
                    changeEditErrors("name", "");
                  }
                }}
              />
              {/* Manufacturer */}
              {inverterEditValues.manufacturer ===
                "-- Manufacturer not on the list --" ||
              inverterEditValues.manufacturer_custom ? (
                <TextField
                  label="Inverter Manufacturer"
                  name="manufacturer_custom"
                  value={inverterEditValues.manufacturer_custom}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(inverterEditErrors.manufacturer)}
                  helperText={inverterEditErrors.manufacturer}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                  }
                  onBlur={() => {
                    if (!inverterEditValues.manufacturer_custom) {
                      inverterEditValues.manufacturer = "";
                    } else {
                      changeEditErrors("manufacturer", "");
                    }
                  }}
                />
              ) : (
                <TextField
                  select
                  label="Inverter Manufacturer"
                  name="manufacturer"
                  value={inverterEditValues.manufacturer}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(inverterEditErrors.manufacturer)}
                  helperText={inverterEditErrors.manufacturer}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                  }
                  onBlur={() => {
                    if (!inverterEditValues.manufacturer) {
                      changeEditErrors(
                        "manufacturer",
                        "Manufacturer is required"
                      );
                    } else {
                      changeEditErrors("manufacturer", "");
                    }
                  }}
                >
                  {Object.entries(inverterDropdown).map(([key, value]) => (
                    <MenuItem value={key}>{key}</MenuItem>
                  ))}
                </TextField>
              )}

              {inverterEditValues.manufacturer ===
                "-- Manufacturer not on the list --" ||
              inverterEditValues.manufacturer_custom ||
              inverterEditValues.type ===
                "-- Inverter type not on the list --" ||
              inverterEditValues.type_custom ? (
                <TextField
                  label="Inverter Type"
                  name="type_custom"
                  value={inverterEditValues.type_custom}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(inverterEditErrors.type_custom)}
                  helperText={inverterEditErrors.type_custom}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                  }
                  onBlur={() => {
                    if (!inverterEditValues.type) {
                      inverterEditValues.type = "";
                    } else {
                      changeEditErrors("type_custom", "");
                    }
                  }}
                />
              ) : (
                <TextField
                  select
                  label="Inverter Type"
                  name="type"
                  value={inverterEditValues.type}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(inverterEditErrors.type)}
                  helperText={inverterEditErrors.type}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                  }
                  onBlur={() => {
                    if (!inverterEditValues.type) {
                      changeEditErrors("type", "Type is required");
                    } else {
                      changeEditErrors("type", "");
                    }
                  }}
                >
                  {Object.keys(inverterDropdown).includes(
                    inverterEditValues.manufacturer
                  )
                    ? inverterDropdown[inverterEditValues.manufacturer].map(
                        (type) => <MenuItem value={type}>{type}</MenuItem>
                      )
                    : null}
                </TextField>
              )}

              <Box sx={{ height: 10 }} />
              <TextField
                select
                label="Inverter Category"
                name="category"
                value={inverterEditValues.category}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                displayEmpty // Add this prop to display the empty value
                error={Boolean(inverterEditErrors.category)}
                helperText={inverterEditErrors.category}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                onBlur={() => {
                  if (!inverterEditValues.category) {
                    changeEditErrors("category", "Category is required");
                  } else {
                    changeEditErrors("category", "");
                  }
                }}
              >
                <MenuItem value={"String Inverter"}>String Inverter</MenuItem>
                <MenuItem value={"Multi String Inverter"}>
                  Multi String Inverter
                </MenuItem>
                <MenuItem value={"Central Inverter"}>Central Inverter</MenuItem>
                <MenuItem value={"Micro Inverter"}>Micro Inverter</MenuItem>
                <MenuItem value={"Hybrid Inverter"}>Hybrid Inverter</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </TextField>
              <Box sx={{ height: 15 }} />
              <TextField
                select
                label="Place of installation"
                name="mounting"
                value={inverterEditValues.mounting}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                displayEmpty // Add this prop to display the empty value
                error={Boolean(inverterEditErrors.mounting)}
                helperText={inverterEditErrors.mounting}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                onBlur={() => {
                  if (!inverterEditValues.mounting) {
                    changeEditErrors("mounting", "Mounting is required");
                  } else {
                    changeEditErrors("mounting", "");
                  }
                }}
              >
                <MenuItem value={"Indoor, air conditioned"}>
                  Indoor, air conditioned
                </MenuItem>
                <MenuItem
                  value={"Indoor, air not conditioned (no risk of overheating)"}
                >
                  Indoor, air not conditioned (no risk of overheating)
                </MenuItem>
                <MenuItem
                  value={"Indoor, air not conditioned (risk of overheating)"}
                >
                  Indoor, air not conditioned (risk of overheating)
                </MenuItem>
                <MenuItem value={"Outdoor, exposed to sun / rain"}>
                  Outdoor, exposed to sun / rain
                </MenuItem>
                <MenuItem value={"Outdoor, protected from sun / rain"}>
                  Outdoor, protected from sun / rain
                </MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </TextField>
              <Box sx={{ height: 10 }} />
              <TextField
                label="AC Power in kW"
                name="ac_power"
                type="number"
                value={inverterEditValues.ac_power}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.ac_power)}
                helperText={inverterEditErrors.ac_power}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kW</InputAdornment>
                  ),
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                sx={{
                  "input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
                onBlur={() => {
                  if (!inverterEditValues.ac_power) {
                    changeEditErrors("ac_power", "AC power is required");
                  } else if (inverterEditValues.ac_power < 0) {
                    changeEditErrors("ac_power", "AC power cannot be negative");
                  } else {
                    changeEditErrors("ac_power", "");
                  }
                }}
              />
              <TextField
                label="DC Power in kW"
                name="dc_power_manual"
                value={inverterEditValues.dc_power_manual}
                onChange={handleInputChange}
                type="number"
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.dc_power_manual)}
                helperText={inverterEditErrors.dc_power_manual}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kW</InputAdornment>
                  ),
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                sx={{
                  "input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
                onBlur={() => {
                  if (!inverterEditValues.dc_power_manual) {
                    changeEditErrors("dc_power_manual", "DC power is required");
                  } else if (inverterEditValues.dc_power_manual < 0) {
                    changeEditErrors(
                      "dc_power_manual",
                      "DC power cannot be negative"
                    );
                  } else {
                    changeEditErrors("dc_power_manual", "");
                  }
                }}
              />
              <TextField
                label="Commissioning Date (DD.MM.YYYY)"
                name="commissioning_date"
                value={inverterEditValues.commissioning_date}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.commissioning_date)}
                helperText={inverterEditErrors.commissioning_date}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                onBlur={() => {
                  if (!inverterEditValues.commissioning_date) {
                    changeEditErrors(
                      "commissioning_date",
                      "Commissioining date is required"
                    );
                  } else if (
                    !inverterEditValues.commissioning_date.match(
                      /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
                    )
                  ) {
                    changeEditErrors("commissioning_date", "Date not valid");
                  } else if (
                    checkDate(
                      plant.commissioning_date,
                      inverterEditValues.commissioning_date
                    )
                  ) {
                    changeEditErrors(
                      "commissioning_date",
                      "Date cannot be earlier than plant commissioning date"
                    );
                  } else if (
                    checkDateNotInFuture(inverterEditValues.commissioning_date)
                  ) {
                    changeEditErrors(
                      "commissioning_date",
                      "Date cannot lie in the future"
                    );
                  } else {
                    changeEditErrors("commissioning_date", "");
                  }
                }}
              />
              <div
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={isSecondHandCheckboxOn}
                  label="is_second_hand"
                  name="is_second_hand"
                  onChange={handleInputChange}
                  value={inverterEditValues.is_second_hand}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                  }
                />
                <Typography variant="body1" color="textPrimary">
                  Second Hand Inverter
                </Typography>
              </div>

              <Typography
                variant="h6"
                style={{ textTransform: "none", marginTop: 10 }}
              >
                Optional Information
              </Typography>

              {/* AC cable length */}
              <TextField
                label="AC cable length in m"
                name="ac_cable_length"
                type="number"
                value={inverterEditValues.ac_cable_length}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.ac_cable_length)}
                helperText={inverterEditErrors.ac_cable_length}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                sx={{
                  "input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
                onBlur={() => {
                  if (
                    inverterEditValues.ac_cable_length &&
                    inverterEditValues.ac_cable_length < 0
                  ) {
                    changeEditErrors(
                      "ac_cable_length",
                      "AC cable length cannot be negative"
                    );
                  } else {
                    changeEditErrors("ac_cable_length", "");
                  }
                }}
              />
              {/* AC cable cross section */}
              <TextField
                label="AC Cable cross section in mm²"
                name="ac_cable_cross_section"
                type="number"
                value={inverterEditValues.ac_cable_cross_section}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.ac_cable_cross_section)}
                helperText={inverterEditErrors.ac_cable_cross_section}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mm²</InputAdornment>
                  ),
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
                sx={{
                  "input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
                onBlur={() => {
                  if (
                    inverterEditValues.ac_cable_cross_section &&
                    inverterEditValues.ac_cable_cross_section < 0
                  ) {
                    changeEditErrors(
                      "ac_cable_cross_section",
                      "AC cable cross section cannot be negative"
                    );
                  } else {
                    changeEditErrors("ac_cable_cross_section", "");
                  }
                }}
              />

              {/* Galvanic Separation */}
              <TextField
                label="Galvanic Separation"
                name="galvanic_separation"
                value={inverterEditValues.galvanic_separation}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.galvanic_separation)}
                helperText={inverterEditErrors.galvanic_separation}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
              />

              {/* Serial Number */}
              <TextField
                label="Serial Number"
                name="serial_number"
                value={inverterEditValues.serial_number}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterEditErrors.serial_number)}
                helperText={inverterEditErrors.serial_number}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleInverterDetailsSaveEdit() : null
                }
              />
            </form>
          ) : (
            <>
              <Typography variant="body2" color="text.secondary">
                <p>
                  <strong>Inverter ID: </strong>
                  {inverter.id}
                </p>
                <p>
                  <strong>Inverter Name: </strong>
                  {inverter.name}
                </p>
                <p>
                  <strong>Inverter Manufacturer: </strong>
                  {inverter.manufacturer}
                </p>
                <p>
                  <strong>Inverter Type: </strong>
                  {inverter.type}
                </p>
                <p>
                  <strong>Inverter Category: </strong>
                  {inverter.category}
                </p>
                <p>
                  <strong>Place of Installation: </strong>
                  {inverter.mounting}
                </p>
                <p>
                  <strong>Inverter Nominal AC Power: </strong>
                  {inverter.ac_power} kW
                </p>
                <p>
                  <strong>DC Power Connected to Inverter: </strong>
                  {inverter.dc_power_manual} kW
                </p>
                <p>
                  <strong>Inverter Commissioning Date: </strong>
                  {inverter.commissioning_date}
                </p>
                <p>
                  <strong>Last Verification: </strong>
                  {inverter.last_status_update}
                </p>
                {inverter.ac_cable_length ? (
                  <p>
                    <strong>AC cable length: </strong>
                    {inverter.ac_cable_length}
                  </p>
                ) : null}
                {inverter.ac_cable_cross_section ? (
                  <p>
                    <strong>AC cable cross section: </strong>
                    {inverter.ac_cable_cross_section}
                  </p>
                ) : null}
                {inverter.galvanic_separation ? (
                  <p>
                    <strong>Galvanic separation: </strong>
                    {inverter.galvanic_separation}
                  </p>
                ) : null}
                {inverter.serial_number ? (
                  <p>
                    <strong>Serial Number: </strong>
                    {inverter.serial_number}
                  </p>
                ) : null}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>

      {/* Inverter Management CArd */}
      <Card style={{ maxWidth: 1200, margin: "20px auto" }} elevation={5}>
        <CardContent>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
            }}
          >
            {/* Button to navigate back to the plant */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                color="info"
                onClick={() => {
                  navigate(`/plants/${id}/`);
                }}
              >
                Back to plant
              </Button>
            </Grid>

            {/* Button to create a new inverter */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {inverter.status === "operational" ? (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    setIsCreateInverterDialogOpen(true);
                  }}
                >
                  Add another inverter
                </Button>
              ) : null}
            </Grid>

            {/* Button to duplicate the inverter */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {inverter.status === "operational" ? (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    duplicateInverter(true);
                  }}
                >
                  Duplicate inverter
                </Button>
              ) : null}
            </Grid>

            {/* Delete Button */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setIsDeleting(true);
                }}
              >
                Delete Inverter
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Strings Grid Card */}
      <Card style={{ maxWidth: 1200, margin: "20px auto" }} elevation={5}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="div">
              Strings
            </Typography>
          </div>

          <StringsGrid
            strings={strings}
            setIsCreateStringDialogOpen={setIsCreateStringDialogOpen}
            inverterStatus={inverter.status}
          />
        </CardContent>
      </Card>

      {/* Inverter Status History */}
      <Card style={{ maxWidth: 1200, margin: "20px auto" }} elevation={5}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography variant="h5" component="div">
              History
            </Typography>
          </div>

          {inverter && inverter.commissioning_date && (
            <History
              inverterId={inverterId}
              inverterCommissioningDate={inverter.commissioning_date}
            />
          )}
        </CardContent>
      </Card>

      {/* Dialog for adding new string */}
      <CreateStringDialog
        isOpen={isCreateStringDialogOpen}
        onClose={() => {
          setIsCreateStringDialogOpen(false);
        }}
        plantId={id}
        inverterId={inverter.id}
      />
    </div>
  );
}

export default InverterDetails;
