import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
} from "@mui/material";
import apiService from "../services/apiService";

const History = ({ inverterId, inverterCommissioningDate }) => {
  /*
  History table for a given inverter
  */

  const [inverterStatusList, setInverStatusList] = useState([]);
  const [commissioningDate, setCommissioningDate] = useState();

  useEffect(() => {
    const [day, month, year] = String(inverterCommissioningDate).split(".");
    setCommissioningDate(year + "-" + month + "-" + day + " ");
  }, [inverterCommissioningDate]);

  useEffect(() => {
    const fetchInverterStatus = async () => {
      try {
        const response = await apiService.getInverterStatus(inverterId);

        if (response && response.hasOwnProperty("status")) {
          const inverterStatuses = response.status.reverse();
          setInverStatusList(
            inverterStatuses.filter(
              (inverter) => inverter.inverter_id === inverterId
            )
          );
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    fetchInverterStatus();
  }, [inverterId]);

  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>Timestamp</TableCell>
            <TableCell align="left" sx={{ width: "20%" }}>
              Status
            </TableCell>
            <TableCell align="left" sx={{ width: "20%" }}>
              Date
            </TableCell>
            <TableCell align="left" sx={{ width: "40%" }}>
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inverterStatusList.map((row) =>
            row.description === "Created" ? (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.timestamp}
                </TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{commissioningDate}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
              </TableRow>
            ) : row.description === "Confirmed still operational" ? (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.timestamp}
                </TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.timestamp}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
              </TableRow>
            ) : (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.timestamp}
                </TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.failure_date}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default History;
